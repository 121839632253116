<template>
	<div class="display-flex h100 bg-white boderra8 box-shadow0010 flex-column align-items-center relative">
		<div class="paddt90">
			<img class="head-pic display-block" :src="avatar" alt="" />
		</div>
		<el-upload class="avatar-uploader" action="https://app.archipocket.com/api/upload"
		    :show-file-list="false" :on-success="handleAvatarSuccess1"
		    :before-upload="beforeAvatarUpload">
			<div class="btn-01 center fz16 co-010085 mart20 cure-pointer" >修改头像</div>
		</el-upload>
		<view class="w100 mart70">
			<div class="">
				<div class="paddlr140 flex-wrap justify-content-center-between">
					<div class="item-info align-items-center marb30">
						<span class="fz16 l16 co-999999 center">昵称</span>
						<input class="fz16 co-333333 h54 flex-1 name-input marl26" v-model="name" placeholder="请输入姓名" />
					</div>
					<div class="item-info align-items-center marb30">
						<span class="fz16 l16 co-999999 center">真实姓名</span>
						<input class="fz16 co-333333 h54 flex-1 name-input marl26" v-model="realname"
							placeholder="请输入真实姓名" />
					</div>
					<div class="item-info align-items-center marb30">
						<span class="fz16 l16 co-999999 center">性别</span>
						<div class="align-items-center marl26 flex-1">
							<div class="align-items-center h54 cure-pointer" @click="sex = 1">
								<img class="sex-icon display-block"
									:src="require(`@/assets/img/${sex == 1 ? 'is_have' : 'no_choose'}.png`)" alt="">
								<span class="fz16 co-333333 display-block marl10 l16">男</span>
							</div>
							<div class="align-items-center marl30 h54 cure-pointer" @click="sex = 0">
								<img class="sex-icon display-block"
									:src="require(`@/assets/img/${sex == 0 ? 'is_have' : 'no_choose'}.png`)" alt="">
								<span class="fz16 co-333333 display-block marl10 l16">女</span>
							</div>
						</div>
					</div>
					<div class="item-info align-items-center marb30">
						<span class="fz16 l16 co-999999 center">年龄</span>
						<input class="fz16 co-333333 h54 flex-1 name-input marl26" v-model="age" placeholder="请输入年龄" />
					</div>
					<div class="item-info align-items-center">
						<span class="fz16 l16 co-999999 center">院校</span>
						<input class="fz16 co-333333 h54 flex-1 name-input marl26" v-model="school"
							placeholder="请输入院校" />
					</div>
					<div class="item-info align-items-center">
						<span class="fz16 l16 co-999999 center">专业</span>
						<input class="fz16 co-333333 h54 flex-1 name-input marl26" v-model="major"
							placeholder="请输入专业" />
					</div>
				</div>
			</div>
		</view>

		<div class="absolute left0 right0 save-area center">
			<div class="center fz16 co-010085 btn-02 cure-pointer" @click="save">保存</div>
		</div>
	</div>
</template>

<script>
	import {
		getCurrentInstance,
		ref,
		reactive,
		watch
	} from 'vue';
	export default {
		setup() {
			const req1 = getCurrentInstance();
			const request = req1.appContext.config.globalProperties;
			return {
				request,
			};
		},
		data() {
			return {
				name: '', // 姓名
				realname: '', // 真实姓名
				age: '', // 年龄
				school: '', // 学校
				major: '', // 专业
				sex: '', // 1男 2女
				avatar:'',
				user_info: {}
			}
		},
		mounted() {
			if (localStorage.getItem('token')) {
				if (localStorage.getItem('user_info')) {
					this.user_info = JSON.parse(localStorage.getItem('user_info'))
					this.name = this.user_info.nickname||this.user_info.wx_name
					this.avatar = this.user_info.avatar
					this.realname = this.user_info.real_name
					this.age = this.user_info.age
					this.school = this.user_info.school
					this.major = this.user_info.profess
					this.sex = this.user_info.sex;
				
				}
			}
		},
		methods: {
			handleAvatarSuccess1(res) {
			    this.avatar = res.data;		 
			},
			beforeAvatarUpload(file) {
			    const isLt4M = file.size / 1024 / 1024 < 4;
			    if (!isLt4M) {
			        this.$message.error('上传图片大小不能超过 4MB!');
			    }
			    return isLt4M;
			},
			// 保存
			save() {
				if (!this.name) {
					this.$message.error('请输入姓名')
					return false
				}
				this.$req({
					method: 'post',
					url: '/api/member',
					data:{
						type:4,
						nickname:this.name,
						avatar:this.avatar,
						real_name:this.realname,
						age:this.age,
						school:this.school,
						profess:this.major,
						sex:this.sex,
					},
					success: res => {
						this.$message.success('保存成功')
						window.requestAnimationFrame(() => {
							this.request.tokenSuccess();
						})
						// location.reload(); 
					},
					fail: error => {}
				});
			
			}
		}

}
</script>

<style lang="less" scoped>
	.sex-icon {
		width: 18px;
		height: 18px;
	}

	.item-info {
		width: 320px;
		height: 54px;
		border-bottom: 1px solid #E6E6E6;

		.name-input {
			line-height: 54px;
			outline: none;
			border: 0;
			margin: 0;
			padding: 0;
			margin-left: 26px;
		}
	}

	.head-pic {
		width: 146px;
		height: 146px;
		border-radius: 50%;
	}

	.btn-01 {
		width: 120px;
		height: 40px;
		background: #DCFF03;
		border-radius: 4px;
	}

	.name-area {
		width: 320px;
		height: 54px;
		border: 1px solid #CCCCCC;
		border-radius: 4px;


	}

	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}

	.save-area {
		bottom: 30px;
	}

	.btn-02 {
		width: 160px;
		height: 50px;
		background: #DCFF03;
		border-radius: 25px;
	}
</style>
